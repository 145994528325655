import React from "react";
import {Link, Popover, Icon} from "@amzn/awsui-components-react-v3";

export const HeaderCell: React.FunctionComponent<{ text: string, description: React.ReactNode, columnType: string }>
    = ({text, description, columnType}) => {
    return <>
        <p style={{minHeight: '100px', margin: '2px', alignItems: 'center'}} data-testid={"header-cell-text"}>{text}</p>
        <hr/>
        <p>
            <Popover
                dismissButton={true}
                position="top"
                size="small"
                triggerType="custom"
                content={description}
                header={columnType}
            >
                <Link variant="info"> <Icon
                    name={'status-info'}
                    size={'medium'}
                    variant={'link'}
                /> Know more </Link>
            </Popover>
        </p>
    </>
}
