import {logger} from "src/utils/logger";

/**
 * Calculates the epoch timestamp for a given number of days ago.
 *
 * @param daysAgo - The number of days to subtract from the current date.
 * @returns The epoch timestamp (in milliseconds) for the calculated date.
 * Returns 0 if the input is invalid.
 */
export const getEpochTimestampForDaysAgo = (daysAgo: number): number => {
    // Ensure daysAgo is a non-negative number
    if (daysAgo < 0 || isNaN(daysAgo)) {
        logger.error(`daysAgo must be a non-negative number: ${daysAgo}. Returning default value 0`);
        return 0;
    }

    // Get the current date
    const currentDate = new Date();

    // Subtract the specified number of days
    const targetDate = new Date(currentDate.getTime() - daysAgo * 24 * 60 * 60 * 1000);

    // Return the epoch timestamp (in milliseconds)
    return targetDate.getTime();
};

/**
 * Calculates the number of days between a given epoch timestamp and the current date.
 *
 * @param epochTimestamp - The epoch timestamp (in milliseconds) to calculate days from.
 * @returns The number of days between the given timestamp and the current date, rounded to one decimal place.
 * Returns 0 if the input is invalid.
 */
export const getDaysAgoFromEpochTimestamp = (epochTimestamp: number): number => {
    // Ensure epochTimestamp is a valid number
    if (isNaN(epochTimestamp) || epochTimestamp < 0) {
        logger.error(`epochTimestamp must be a non-negative number: ${epochTimestamp}. Returning default value 0`);
        return 0;
    }

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceMs = currentDate.getTime() - epochTimestamp;

    // Convert milliseconds to days and round to one decimal place
    return Number((differenceMs / (1000 * 60 * 60 * 24)).toFixed(1));
};
