import {PropertyFilterProps} from "@amzn/awsui-components-react-v3";
import {
    END_TIME_MAX,
    FilterOperation,
    MAX_DAYS_SINCE_HEAD_STAGE, MIN_DAYS_SINCE_HEAD_STAGE,
    QueryVerb, START_TIME_MIN,
    TIME_ELAPSED_FIELD_NAMES,
    FilterTokenOperators, FilterPropertyGroups, filterPropertyConfigMap, DataStoreFields
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";
import {PropertyFilterToken} from "@amzn/awsui-collection-hooks/dist/cjs/interfaces";
import {getDaysAgoFromEpochTimestamp, getEpochTimestampForDaysAgo} from "src/utils/time-helper";

export const convertFilterTokensToUrlQuery = (filterTokens: PropertyFilterProps.Query) => {
    return filterTokens.tokens.reduce((filterQuery, token) => {
        if(filterPropertyConfigMap[token.propertyKey|| ''].group === FilterPropertyGroups.TIME_FILTERS){
            const queryKey = `${QueryVerb.MUST}::${FilterOperation.RANGE}:${token.propertyKey}`;
            const [startTime, endTime] = (filterQuery[queryKey] || '').split(':');

            if (token.propertyKey === DataStoreFields.DAYS_SINCE_HEAD_STAGE) {
                let epochStartTime, epochEndTime;
                if (token.operator === FilterTokenOperators.GREATER_THAN_EQUAL) {
                    epochEndTime = getEpochTimestampForDaysAgo(parseFloat(token.value || MIN_DAYS_SINCE_HEAD_STAGE.toString())).toString();
                    epochStartTime = startTime || getEpochTimestampForDaysAgo(MAX_DAYS_SINCE_HEAD_STAGE).toString();
                } else if (token.operator === FilterTokenOperators.LESS_THAN_EQUAL) {
                    epochStartTime = getEpochTimestampForDaysAgo(parseFloat(token.value || MAX_DAYS_SINCE_HEAD_STAGE.toString())).toString();
                    epochEndTime = endTime || getEpochTimestampForDaysAgo(MIN_DAYS_SINCE_HEAD_STAGE).toString();
                }
                filterQuery[queryKey] = `${epochStartTime}:${epochEndTime}`;
            } else {
                // Handle other time filters as before
                const epochTime = Date.parse(token.value);
                if (token.operator === FilterTokenOperators.GREATER_THAN_EQUAL) {
                    filterQuery[queryKey] = `${epochTime}:${endTime || END_TIME_MAX}`;
                } else if (token.operator === FilterTokenOperators.LESS_THAN_EQUAL) {
                    filterQuery[queryKey] = `${startTime || START_TIME_MIN}:${epochTime}`;
                }
            }
            return filterQuery;
        } else if (filterPropertyConfigMap[token.propertyKey|| ''].group === FilterPropertyGroups.MULTI_SELECT_FILTERS) {
            if(token.operator === FilterTokenOperators.NOT_EQUAL){
                filterQuery[`${QueryVerb.MUST_NOT}::${FilterOperation.MATCH_ANY}:${token.propertyKey}`] = token.value;
            }else{
                filterQuery[`${QueryVerb.MUST}::${FilterOperation.MATCH_ANY}:${token.propertyKey}`] = token.value;
            }
            return filterQuery;
        }else if (filterPropertyConfigMap[token.propertyKey|| ''].group === FilterPropertyGroups.SELECT_FILTERS) {
            /**
            Todo: Array fields are indexed as strings, so we are using match phrase, till we fix, that, we are using
            this filter for single select fields.
            */
            filterQuery[`${QueryVerb.MUST}::${FilterOperation.LIKE}:${token.propertyKey}`] = token.value;
            return filterQuery;
        }

        switch (token.operator) {
            case FilterTokenOperators.EQUAL:
                filterQuery[`${ QueryVerb.MUST}::${getOperationForFreeTextFilter(token.value)}:${token.propertyKey}`] = token.value;
                return filterQuery;
            case FilterTokenOperators.NOT_EQUAL:
                filterQuery[`${QueryVerb.MUST_NOT}::${getOperationForFreeTextFilter(token.value)}:${token.propertyKey}`] = token.value;
                return filterQuery;
            case FilterTokenOperators.REGEX:
                filterQuery[`${QueryVerb.MUST}::${FilterOperation.REGEX}:${token.propertyKey}`] = token.value;
                return filterQuery;
        }

        return filterQuery
    }, {} as Record<string, string>);
}

export const convertFilterUrlQueryToFilterTokens = (filterUrRlQuery: Record<string, string>) => {
    return Object.keys(filterUrRlQuery).reduce((filterTokens: PropertyFilterToken[], filter: string) => {
        const [queryVerb, filterOpsData] = filter.split('::');
        const [filterOp, property] = filterOpsData.split(':');
        if(queryVerb === QueryVerb.MUST_NOT) {
            filterTokens.push({
                operator: FilterTokenOperators.NOT_EQUAL,
                propertyKey: property,
                value: filterUrRlQuery[filter]
            })
        }else {
            switch (filterOp) {
                case FilterOperation.MATCH:
                    filterTokens.push({
                        operator: FilterTokenOperators.EQUAL,
                        propertyKey: property,
                        value: filterUrRlQuery[filter]
                    });
                    break;
                case FilterOperation.REGEX:
                    filterTokens.push({
                        operator: FilterTokenOperators.REGEX,
                        propertyKey: property,
                        value: filterUrRlQuery[filter]
                    });
                    break;
                case FilterOperation.MATCH_ANY:
                    filterTokens.push({
                        operator: FilterTokenOperators.EQUAL,
                        propertyKey: property,
                        value: filterUrRlQuery[filter]
                    });
                    break;
                case FilterOperation.LIKE:
                    filterTokens.push({
                        operator: FilterTokenOperators.EQUAL,
                        propertyKey: property,
                        value: filterUrRlQuery[filter]
                    });
                    break;
                case TIME_ELAPSED_FIELD_NAMES.includes(property) && FilterOperation.RANGE: {
                    const [rangeStart, rangeEnd] = filterUrRlQuery[filter].split(':');
                    filterTokens.push({
                        operator: FilterTokenOperators.GREATER_THAN_EQUAL,
                        propertyKey: property,
                        value: `${getDaysAgoFromEpochTimestamp(parseFloat(rangeEnd))}`
                    });
                    filterTokens.push({
                        operator: FilterTokenOperators.LESS_THAN_EQUAL,
                        propertyKey: property,
                        value: `${getDaysAgoFromEpochTimestamp(parseFloat(rangeStart))}`
                    })
                    break;
                }
                case FilterOperation.RANGE:
                    const [rangeStart, rangeEnd] = filterUrRlQuery[filter].split(':');
                    const rangeStartDate = new Date(parseInt(rangeStart));
                    const rangeEndDate = new Date(parseInt(rangeEnd));
                    filterTokens.push({
                        operator: FilterTokenOperators.GREATER_THAN_EQUAL,
                        propertyKey: property,
                        value: `${rangeStartDate.getFullYear()}-${rangeStartDate.getMonth() + 1}-${rangeStartDate.getDate()}`
                    })
                    filterTokens.push({
                        operator: FilterTokenOperators.LESS_THAN_EQUAL,
                        propertyKey: property,
                        value:  `${rangeEndDate.getFullYear()}-${rangeEndDate.getMonth() + 1}-${rangeEndDate.getDate()}`
                    })
                    break;
            }
        }
        return [...filterTokens];
    }, [])
}

const getOperationForFreeTextFilter = (value: string) => {
    const areMultipleValuesPresent = value.split(',').length > 1;
    return areMultipleValuesPresent ? FilterOperation.MATCH_ANY : FilterOperation.MATCH;
}
