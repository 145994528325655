import React from "react";
import {TableProps} from "@amzn/awsui-components-react-v3/polaris/table/interfaces";
import {
    WorkFlowStatusIndicator
} from "src/components/sm-overview-dashboards/workflow-list-view/workflow-list-view.utils";
import {ExternalRoutes} from "src/components/common/router-common";
import {Link} from "@amzn/awsui-components-react-v3";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {fixStringCasing} from "src/utils/string-helper";

export const workflowListViewColumnDefinition:
    Array<TableProps.ColumnDefinition<SMSWOUnifiedDashboardServiceLambda.Types.WorkflowRecord>> = [
    {
        id: 'datasource',
        header: 'Data source',
        cell: (item) => item.domain,
        minWidth: 50
    },
    {
        id: 'creationDate',
        header: 'Creation Date',
        cell: (item) => item.workflowCreateTimestamp
            ? new Date(item.workflowCreateTimestamp).toUTCString() : '',
        minWidth: 50
    },
    {
        id: 'endDate',
        header: 'Last Update Date',
        cell: (item) => item.workflowLastUpdateTimestamp
            ? new Date(item.workflowLastUpdateTimestamp).toUTCString() : '',
        minWidth: 150
    },
    {
        id: 'lastRunStartDate',
        header: 'Last Run Start Date',
        cell: (item) => item.workflowRunCreateTimestamp
            ? new Date(item.workflowRunCreateTimestamp).toUTCString() : '',
        minWidth: 150
    },
    {
        id: 'workflowStatus',
        header: 'Status',
        cell: (item) => <WorkFlowStatusIndicator status={item.state || ''} />,
        minWidth: 120

    },
    {
        id: 'headStage',
        header: 'Head Stage',
        cell: (item) => fixStringCasing(item.headStage || ''),
        minWidth: 50
    },
    {
        id: 'headStageStatus',
        header: 'Head Stage Status',
        cell: (item) => <WorkFlowStatusIndicator status={item.headStageStatus|| ''} />,
        minWidth: 120
    },
    {
        id: 'Days Since Head Stage',
        header: 'Days Since Head Stage',
        cell: (item) => {
            if (item.executingIndependentlySince) {
                const executionStartDate = new Date(item.executingIndependentlySince);
                const today = new Date();
                const diffTime = today.getTime() - executionStartDate.getTime();
                return Number((diffTime / (1000 * 60 * 60 * 24)).toFixed(1));
            }
            return '';
        },
        minWidth: 150
    },
    {
        id: 'errorCode',
        header: 'Error',
        cell: (item) => item.error,
        minWidth: 50
    },
    {
        id: 'errorCause',
        header: 'Error Cause',
        cell: (item) => item.cause,
        minWidth: 50,
    },
    {
        id: 'rorLink',
        header: 'ROR Link',
        cell: (item) => (
            <Link
                href={ExternalRoutes.workflowInstanceSIM(item.recipeExecutionId||'')}
                external={true} >
                ROR Link
            </Link>
        ),
        minWidth: 50
    }
]
